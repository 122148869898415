import ApplicationController from './application_controller'
import { useTransitionedRow, useSpinner } from './mixins'
import debounce from 'lodash/debounce'

export default class extends ApplicationController {
  static targets = [
    'actionBtn',
    'quantityInput',
    'priceInput',
    'lotCodeInput',
    'unitCostInput',
    'spin',
    'lotCodeSelect'
  ]

  connect() {
    super.connect()
    useTransitionedRow(this)
    useSpinner(this)

    this.onQuantityInputChange = debounce(this.onQuantityInputChange, 800, {
      leading: false,
      trailing: true
    })
    this.onPriceInputChange = debounce(this.onQuantityPriceChange, 800)
  }

  beforeReflex() {
    this.element.classList.add('opacity-75')
    this._disableInputs()
    this._showRowSpinner()
  }

  afterReflex() {
    this._enableInputs()
    this.element.classList.add('opacity-100')
    this._hideRowSpinner()
  }

  reflexSuccess() {
    this.animateRow(this.element, { rowTag: 'TR' })
  }

  reflexError() {
    this._enableInputs()
    this._hideRowSpinner()
  }

  onQuantityInputChange(event) {
    this._updateQuantity(event.target.value, event.target.dataset.reflexFunction)
  }

  onQuantityPriceChange(event) {
    this._updatePrice(event.target.value)
  }

  onOpsLotCodeSelect(event) {
    this.stimulate(
      `${this.element.dataset.reflexName}#update_lot_from_ops`,
      this.element,
      event.target.value
    )
  }

  onLotCodeSelect(event) {
    this._updateLotCodeId(event.currentTarget.dataset.lotCodeId)
  }

  onLotCodeChange(event) {
    this._updateLotCode(event.target.value)
  }

  onUnitCostChange(event) {
    this._updateUnitCost(event.target.value)
  }

  _updateQuantity(quantity, reflex_function = "update_or_create") {
    let lot_id = null
    if (this.hasLotCodeSelectTarget) lot_id = this.lotCodeSelectTarget.value

    if (this.quantityInputTarget.validity.valid) {
      this.stimulate(`${this.element.dataset.reflexName}#${reflex_function}`, this.element, [
        {
          liquid_variant_id: this.element.dataset.productVariantId,
          id: this.element.dataset.lineItemId,
          line_item: {
            quantity: parseFloat(quantity.replace(',', '')),
            lot_id
          }
        }
      ])
    }
  }

  _updatePrice(unit_price) {
    this.stimulate(`${this.element.dataset.reflexName}#update_or_create`, this.element, [
      {
        liquid_variant_id: this.element.dataset.productVariantId,
        id: this.element.dataset.lineItemId,
        line_item: { unit_price: unit_price }
      }
    ])
  }

  _updateLotCodeId(inventory_lot_id) {
    this.stimulate(
      `${this.element.dataset.reflexName}#update_inventory_lot_id`,
      this.element,
      inventory_lot_id
    )
  }

  _updateLotCode(lot_code) {
    this.stimulate(`${this.element.dataset.reflexName}#update_lot_code`, this.element, lot_code)
  }

  _updateUnitCost(unit_cost) {
    this.stimulate(`${this.element.dataset.reflexName}#update_unit_cost`, this.element, unit_cost)
  }

  _showRowSpinner() {
    if (this.hasActionBtnTarget) {
      this.actionBtnTargets.forEach((btn) => btn.classList.add('hidden'))
    }
    this.showSpinner()
  }

  _hideRowSpinner() {
    this.spinTarget.classList.add('hidden')
    if (this.hasActionBtnTarget) {
      this.actionBtnTargets.forEach((btn) => btn.classList.remove('hidden'))
    }
  }

  _disableInputs() {
    const { decrement, increment } = this.quantityInputButtons

    this.quantityInputTarget.disabled = true

    if (this.hasPriceInputTarget) this.priceInputTarget.disabled = true
    if (this.hasLotCodeInputTarget) this.lotCodeInputTarget.disabled = true
    if (this.hasUnitCostInputTarget) this.unitCostInputTarget.readonly = true
    if (this.hasLotCodeSelectTarget) this.lotCodeSelectTarget.disabled = true

    if (decrement && increment) {
      decrement.disabled = true
      increment.disabled = true
    }
  }

  _enableInputs() {
    const { decrement, increment } = this.quantityInputButtons

    this.quantityInputTarget.disabled = false

    if (this.hasPriceInputTarget) this.priceInputTarget.disabled = false
    if (this.hasLotCodeInputTarget) this.lotCodeInputTarget.disabled = false
    if (this.hasUnitCostInputTarget) this.unitCostInputTarget.readonly = false
    if (this.hasLotCodeSelectTarget) this.lotCodeSelectTarget.disabled = false

    if (decrement && increment) {
      decrement.disabled = false
      increment.disabled = false
    }
  }

  get quantityInputButtons() {
    return {
      decrement: this.element.querySelector(
        'button[data-quantity-input-component-target="decrementBtn"]'
      ),
      increment: this.element.querySelector(
        'button[data-quantity-input-component-target="incrementBtn"]'
      )
    }
  }
}
